<template>
  <UserSingleCard showLogo>
    <template v-slot:title>
      <p class="login-title">ログイン</p>
    </template>

    <template v-slot:contents>
      <p class="login-email-title">メールアドレス</p>
      <input class="email" type="email" v-model="email" />

      <p class="login-password-title">パスワード</p>
      <input class="password" type="password" v-model="password"/>
    </template>

    <template v-slot:button>
      <WideButton
        class="login-button"
        :label="'ログイン'"
        :disabled="(!email || !password)"
        @click="$_logIn"
      />
    </template>

    <template v-slot:append>
      <p
        class="forgot-password"
        @click="$router.push('/user/send_reset_password')"
      >
        パスワードをお忘れの方&gt;
      </p> 
    </template>
  </UserSingleCard>
</template>

<script>
import WideButton from '@/components/atoms/common/AppWideButton'
import UserSingleCard from '@/templates/user/AppUserSingleCard'
import { sendRegistrationCompletionEmail } from '@/helper/functions'
import { showErrorPopupAlert } from '@/helper/common'
import { logInApp } from '@/helper/auth'

/**
 * FIXME: pageに具体的なコンポーネントの配置を記述するのではなく、templateに移すこと
 */
export default {
  name: 'TheLogIn',
  components: {
    UserSingleCard,
    WideButton
  },
  data() {
    return {
      email: '',
      password: '',
    }
  },
  mounted: function () {
    this.$store.dispatch('loadingMask/hideLoadingMask')
  },
  methods: {
    $_logIn: async function () {
      try {
        this.$store.dispatch('loadingMask/showLoadingMask')
        await logInApp(this.email, this.password)

        // applyActionCode実行時にメールアドレスが取れないので、
        // メール認証からのリダイレクト時のログインで登録完了メールを送る
        if (this.$route.query.registrationCompleted) {
          sendRegistrationCompletionEmail(this.email)
        }

        // routing after login
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
        } else {
          this.$router.push('/')
        }
      } catch(e) {
        const errorMessage = this.$_getErrorMessage(e)
        showErrorPopupAlert(errorMessage)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    },
    $_getErrorMessage: function (error) {
      if (error.message.includes('Password should be at least 6 characters')) {
        return 'パスワードは6文字以上の英数字を設定してください'
      } else if (error.message.includes('invalid-email')) {
        return 'メールアドレス/パスワードが不正です'
      } else if (error.message.includes("user-not-found")) {
        return "入力されたメールアドレスに一致するアカウントがみつかりませんでした。"
      } else if (error.message.includes("wrong-password")) {
        return "パスワードが間違っています。"
      } else {
        return error.message
      }
    },
  },
}
</script>

<style lang="scss">
input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  border: 1px solid gray;
  border-radius: 4px;
}
.login-title {
  margin: 16px 0;
}
.login-email-title {
  text-align: left;
  margin-bottom: 8px;
  font-size: 12px;
}
.email {
  margin: 0;
}
.login-password-title {
  text-align: left;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 12px;
}
.password {
  margin: 0;
}
.login-button {
  margin-top: 32px;
}
.forgot-password {
  color: #707070;
  text-decoration: underline;
  cursor: pointer;
}
</style>
